<template>
    <filter-list
        v-model="list"
        ref="list"
        url="/techProductDemand/all"
        :filters="filters"
        isDemand
        :sortKey="sortKey"
        @btnEvent="btnEvent"
        @init="init"
    >
        <template v-for="item in list">
            <tech-product-demand-row :key="item.id" :info="item"></tech-product-demand-row>
        </template>
        <tech-product-demand-add ref="public" @refreash="refreash"></tech-product-demand-add>
    </filter-list>
</template>

<script>
import FilterList from '../../components/page/FilterList.vue';
import {
    patentTypeOptions,
    stageOptions,
    modeOptions,
    moneyOptions,
    tradingMethodOptionsDemands,
    tradingMethodOptionsDemand
} from '../../utils/variables';
import TechProductDemandAdd from '../../components/popup/TechProductDemandAdd.vue';
import TechProductDemandRow from '../../components/list/TechProductDemandRow.vue';
export default {
    components: { FilterList, TechProductDemandRow, TechProductDemandAdd },
    data() {
        return {
            patentTypeOptions,
            stageOptions,
            modeOptions,
            moneyOptions,
            tradingMethodOptionsDemands,
            tradingMethodOptionsDemand,
            industryOptions: [], //领域,
            list: [],
            sortKey: [
                // {
                //     name: '发布时间',
                //     key: 'createdAt'
                // },
                // {
                //     name: '预算',
                //     key: 'expectedPrice'
                // },
                {
                    name: '浏览量',
                    key: 'view'
                }
            ]
        };
    },
    created() {
        this.init();
    },
    computed: {
        filters() {
            const type = [this.TECHNOLOGY, this.PRODUCT];
            return type[this.$route.query.tpType == 'TECHNOLOGY' ? 0 : 1];
        },
        TECHNOLOGY() {
            return [
                {
                    name: '所属领域',
                    key: 'industry',
                    list: [...this.industryOptions]
                },
                {
                    name: '所处阶段',
                    key: 'stage',
                    list: [...this.stageOptions]
                },
                {
                    name: '交易方式',
                    key: 'modeId',
                    list: [...this.tradingMethodOptionsDemand]
                }
                // {
                //     name: '预算区间',
                //     key: 'expectedPrice',
                //     list: [...this.moneyOptions]
                // },
                // {
                //     name: '发布时间',
                //     key: 'createdAt',
                //     list: [...this.createdAtOptions]
                // }
            ];
        },
        PRODUCT() {
            return [
                {
                    name: '所属领域',
                    key: 'industry',
                    list: [...this.industryOptions]
                },
                {
                    name: '所处阶段',
                    key: 'stage',
                    list: [...this.stageOptions]
                },
                {
                    name: '交易方式',
                    key: 'modeId',
                    list: [...this.tradingMethodOptionsDemands]
                }
                // {
                //     name: '预算区间',
                //     key: 'expectedPrice',
                //     list: [...this.moneyOptions]
                // },
                // {
                //     name: '发布时间',
                //     key: 'createdAt',
                //     list: [...this.createdAtOptions]
                // }
            ];
        },
        createdAtOptions() {
            return [
                {
                    label: '一周内',
                    value: this.getTimeBefor()
                },
                {
                    label: '一月内',
                    value: this.getTimeOnem()
                },
                {
                    label: '二月内',
                    value: this.getTimeTom()
                },
                {
                    label: '三月内',
                    value: this.getTimeTherrm()
                },
                {
                    label: '三月以上',
                    value: this.getTimeTherrms()
                }
            ];
        }
    },
    methods: {
        init() {
            this.$http
                .post('/setting/byFlag', { flag: 1 })
                .then(res => {
                    if (res.length > 0) {
                        this.industryOptions = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    }

                    this.$nextTick(() => {
                        this.$refs.list.init();
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        },
        btnEvent(value) {
            if (value) {
                // this.$refs.public.init(this.$route.query.tpType);
                this.checkLogin(false).then(() => {
                    this.$refs.public.init(this.$route.query.tpType);
                });
            }
        },
        refreash() {
            this.$refs.list.getData();
        }
    }
};
</script>

<style lang="less" scoped></style>
