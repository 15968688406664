<template>
    <router-link
        :to="{
            name: 'technologyProductDemandDetail',
            query: {
                id: info.id
            }
        }"
        class="funding"
    >
        <div class="content">
            <div class="name">
                {{ info.name }}
            </div>
            <div class="info">
                <div class="info-item">
                    <span class="text1">所属领域：</span>
                    <span class="text2">{{ info.industryName }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">预算：</span>
                    <span class="text2" v-if="info.negotiateDirectly">面议 </span>
                    <span class="text2" v-else>{{ info.expectedPrice }}万元 </span>
                </div>

                <div class="info-item">
                    <span class="text1">发布时间:</span>
                    <span class="text2">{{ formatTime(info.createdAt, 'YYYY.MM.DD') }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">交易方式：</span>
                    <span v-if="info.tpType === 'PRODUCT'" class="text2">{{ modes }}</span>
                    <span v-else class="text2">{{ info.mode }}</span>
                </div>
                <!-- <div class="info-item" v-if="info.isPatented">
                    <span class="text1">专利类型：</span>
                    <span class="text2">{{ patentType }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">所属阶段：</span>
                    <span class="text2">{{ stage }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">交易方式：</span>
                    <span class="text2">{{ mode }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">联系人：</span>
                    <span class="text2">{{ info.contact }}</span>
                </div> -->
                <!-- 
                <div class="info-item">
                    <span class="text1">电话：</span>
                    <span class="text2">{{ info.phone }}</span>
                </div> 
                <div class="info-item">
                    <span class="text1">地区:</span>
                    <span class="text2">{{ info.address }}</span>
                </div>-->
            </div>
            <div class="look">
                <i class="iconfont iconfont-icon-lliulan"></i>
                <span>{{ info.view }}个人看过</span>
            </div>
            <!-- <div class="sub">
                {{ info.description }}
            </div> -->
        </div>
        <div class="edit-btn" v-if="isEdit">
            <el-button v-if="info.status === 'PASS'" type="primary" size="small" plain @click.prevent="edit"
                >编辑</el-button
            >
            <el-button v-if="info.status === 'PENDING'" type="primary" size="small" plain>审核中</el-button>
            <el-button v-if="info.status === 'DENY'" type="danger" size="small" plain>已拒绝</el-button>
            <el-button size="small" plain @click.prevent="del">删除</el-button>
        </div>
        <div class="btn" v-else>
            <div class="icon-button" @click.prevent="connectKefu">
                <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                咨询
            </div>
            <!-- <el-button type="primary" @click.prevent="postMessage" class="icon-button" size="small" plain>
                <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
            </el-button> -->
        </div>
    </router-link>
</template>
<script>
import {
    patentTypeOptions,
    tradingMethodOptionsDemand,
    tradingMethodOptionsDemands,
    stageOptions
} from '../../utils/variables';
import comEvent from '../../mixins/comEvent';

export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        mode() {
            let info = tradingMethodOptionsDemand.find(item => {
                return item.value === this.info.mode;
            });

            return info ? info.label : '';
        },
        patentType() {
            let info = patentTypeOptions.find(item => {
                return item.value === this.info.patentType;
            });
            return info ? info.label : '';
        },
        stage() {
            let info = stageOptions.find(item => {
                return item.value === this.info.stage;
            });

            return info ? info.label : '';
        }
    },
    mixins: [comEvent],
    methods: {
        edit() {
            this.$router.push({
                path: '/TechProductDemandEdit',
                query: {
                    tpType: this.info.tpType,
                    id: this.info.id
                }
            });
        },
        del() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/techProductDemand/del/${this.info.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$emit('refreash');
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>

<style lang="less" scoped>
.info {
    .flex();
    margin: 10px 0;
    flex-wrap: wrap;
    .info-item {
        width: 24%;
        padding-right: 20px;
        box-sizing: border-box;
        line-height: 32px;
        .flex();

        .text1 {
            font-size: 13px;
            color: #939599;
            display: inline-block;
            flex-shrink: 0;
            // min-width: 70px;
        }

        .text2 {
            font-size: 13px;
            color: #000000;
            margin-left: 10px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.funding {
    padding: 20px;
    margin-bottom: 20px;
    .flex();

    .el-image {
        width: 122px;
        height: 182px;
        flex-shrink: 0;
    }
    position: relative;
    cursor: pointer;
    border: 1px solid rgba(56,85,142,0.04);
    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(81, 81, 81, 0.1);
        border: 1px solid #01a041;
        .text2 {
            color: #01a041;
        }
        .icon-button{
            background: #01A041;
            color: #FFFFFF;
        }
    }
}
.look {
    i {
        font-size: 18px;
        color: #c8c9cc;
        vertical-align: middle;
    }
    span {
        font-size: 13px;
        font-weight: 400;
        color: #818999;
        line-height: 18px;
        vertical-align: middle;
        margin: 0 3px 0 5px;
    }
    // position: absolute;
    // left: 20px;
    // bottom: 26px;
}
.edit-btn {
    .flex();
    .el-button {
        &:first-child {
            width: 88px;
        }

        &:last-child {
            width: 56px;
        }
    }
}
.icon-button {
    width: 134px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #01a041;
    color: #01a041;
    box-sizing: border-box;
    padding: 0px;
}

.funding {
    .flex();
    .content {
        flex-grow: 1;
        padding-right: 50px;
    }
}

.sub {
    font-size: 14px;
    color: #939599;
    line-height: 20px;
    margin-top: 10px;
}

.name {
    font-size: 16px;
    font-weight: bold;
    color: #292c33;
    line-height: 22px;
}
</style>
