<template>
    <el-dialog
        v-loading="saving"
        :title="`发布需求信息 -${formData.tpType === 'TECHNOLOGY' ? '技术' : '产品'}`"
        center
        :visible.sync="show"
        width="980px"
    >
        <el-form
            :model="formData"
            :rules="rules"
            ref="form"
            label-width="200px"
            label-position="right"
            size="small"
            inline
        >
            <el-form-item
                prop="name"
                :label="`${formData.tpType === 'TECHNOLOGY' ? '需求技术成果名称' : '需求产品名称'}`"
            >
                <el-input style="width: 200px" v-model="formData.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="industry" label="所属行业">
                <el-select v-model="formData.industry" clearable filterable placeholder="请选择" style="width: 26%">
                    <el-option
                        v-for="item in industryOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="stage" label="所处阶段" v-if="formData.tpType === 'PRODUCT'">
                <el-select v-model="formData.stage" clearable filterable placeholder="请选择" style="width: 26%">
                    <el-option v-for="item in stageOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="description" label="需求描述" class="block">
                <el-input
                    type="textarea"
                    v-model="formData.description"
                    maxlength="500"
                    show-word-limit
                    :autosize="{ minRows: 6, maxRow: 6 }"
                    placeholder="请填写需求描述，500字以内"
                    resize="none"
                ></el-input>
            </el-form-item>
            <el-form-item
                prop="isPatented"
                :label="`是否需要专利${formData.tpType === 'TECHNOLOGY' ? '技术' : '产品'}`"
                v-if="formData.tpType === 'PRODUCT'"
            >
                <el-radio-group v-model="formData.isPatented">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="patentType" label="专利类型" v-if="formData.isPatented">
                <el-select v-model="formData.patentType" clearable filterable placeholder="请选择" style="width: 200px">
                    <el-option
                        v-for="item in patentTypeOptionss"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="转移转化方式" prop="modeId" v-if="formData.tpType === 'TECHNOLOGY'">
                <el-radio-group v-model="formData.modeId">
                    <el-radio v-for="item in tradingMethodOptionsDemand" :key="item.value" :label="item.value">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="转移转化方式" prop="modeId" v-else>
                <el-radio-group v-model="formData.modeId">
                    <el-radio v-for="item in tradingMethodOptionsDemands" :key="item.value" :label="item.value">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="expectedPrice" label="预算(万元)">
                <el-input-number
                    v-model="formData.expectedPrice"
                    :disabled="formData.negotiateDirectly"
                    @change="Price"
                    :precision="1"
                    :controls="false"
                    placeholder="请输入"
                    style="margin-right: 10px"
                >
                </el-input-number>
                <el-checkbox @change="Directly" v-model="formData.negotiateDirectly">面议</el-checkbox>
            </el-form-item>

            <el-form-item prop="contact" label="联系人">
                <el-input v-model="formData.contact" placeholder="请输入联系人"></el-input>
            </el-form-item>

            <el-form-item prop="phone" label="联系电话">
                <el-input style="width: 200px" v-model="formData.phone" placeholder="请输入联系电话"></el-input>
            </el-form-item>

            <el-form-item prop="address" label="所在地区">
                <el-input style="width: 400px" v-model="formData.address" placeholder="请输入所在地址"></el-input>
            </el-form-item>

            <el-form-item prop="email" label="邮箱">
                <el-input style="width: 200px" v-model="formData.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
        </el-form>
        <div class="btns">
            <el-button type="info" size="small" @click="show = false">返回</el-button>
            <el-button type="primary" size="small" @click="onSave">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
// import RichText from '../RichText.vue';
import {
    stageOptions,
    tradingMethodOptionsDemands,
    tradingMethodOptionsDemand,
    patentTypeOptions,
    phonePattern
} from '../../utils/variables';
import { mapState } from 'vuex';
export default {
    name: 'TechProductDemandEdit',
    data() {
        return {
            needChange: true,
            show: false,
            saving: false,
            formData: {
                isPatented: false
            },
            industryOptions: [],
            stageOptions,
            patentTypeOptionss: [
                { label: '发明', value: 'INVENTION' },
                { label: '实用新型', value: 'UTILITY_MODEL' },
                { label: '外观设计', value: 'APPEARANCE_DESIGN' }
            ],
            tradingMethodOptionsDemands,
            tradingMethodOptionsDemand
        };
    },
    created() {
        this.$http
            .post('/setting/byFlag', { flag: 1 })
            .then(res => {
                if (res.length > 0) {
                    res.forEach(item => {
                        this.industryOptions.push({
                            label: item.name,
                            value: item.id
                        });
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
    },
    computed: {
        ...mapState(['userInfo']),
        types() {
            return this.$route.query.tpType;
        },
        rules() {
            return {
                name: [
                    {
                        required: true,
                        message: `请输入需求${this.types === 'TECHNOLOGY' ? '技术成果' : '产品'}名称`
                    }
                ],
                // industry: [{ required: true, message: '请选择所属领域' }],
                // stage: [{ required: true, message: '请选择所处阶段' }],
                // patentType: [{ required: true, message: '请选择专利类型', trigger: 'blur' }],
                // description: [{ required: true, message: '请填写需求描述' }],
                // isPatented: [
                //     {
                //         required: true,
                //         message: `请选择是否需要专利${this.types === 'TECHNOLOGY' ? '技术' : '产品'}`,
                //         trigger: 'blur'
                //     }
                // ],
                // modeId: [{ required: true, message: '请选择交易方式' }],
                // expectedPrice: [
                //     {
                //         required: true,
                //         validator: (rule, value, callback) => {
                //             if (!this.formData.negotiateDirectly) {
                //                 if (!this.formData.expectedPrice) {
                //                     callback(new Error('请选择预算方式'));
                //                 } else {
                //                     callback();
                //                 }
                //             } else {
                //                 callback();
                //             }
                //         }
                //     }
                // ],
                // contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                // phone: [{ required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' }],
                // email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                // address: [{ required: true, message: '请输入所在地区' }]
            };
        }
    },
    methods: {
        Price() {
            if (this.formData.expectedPrice) {
                this.formData.negotiateDirectly = false;
            }
        },
        Directly() {
            if (this.formData.negotiateDirectly == true) {
                this.$set(this.formData, 'expectedPrice', undefined);
            }
            this.$forceUpdate();
        },
        init(types) {
            this.show = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                this.formData = {
                    userId: this.userInfo.id,
                    tpType: types,
                    negotiateDirectly: false,
                    // mode: 'TRANSFER',
                    contact: this.userInfo.nickname,
                    phone: this.userInfo.phone,
                    email: this.userInfo.email
                };
            });
        },
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.init(this.types);
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData, status: 'PENDING' };
            // console.log(data);
            this.saving = true;
            this.$http
                .post('/techProductDemand/save', data, { body: 'json' })
                .then(() => {
                    // console.log(res);
                    this.saving = false;
                    this.$message.success('发布成功');
                    this.show = false;
                    this.$emit('refreash');
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        }
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog .el-form-item {
    width: 100%;
}
.el-tag {
    margin-bottom: 11px;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.input-new-tag {
    width: 100%;
    vertical-align: bottom;
}
/deep/ .el-checkbox__inner {
    border-radius: 50%;
}
/deep/ .el-dialog__header {
    padding: 17px 0px !important;
}
/deep/ .el-form {
    width: 100% !important;
    margin: 0px !important;
}
/deep/ .el-select{
    width: 93% !important;
}
</style>
